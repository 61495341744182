<template>
    <div class="publication-stream-blocks">
        <Anchor v-if="heading" :url="`#${anchorId}`" class="heading">
            <Typography v-if="heading" as="h2" variant="h2">{{ heading }}</Typography>
            <Icon name="link" class="heading-icon"></Icon>
        </Anchor>

        <div class="blocks">
            <StreamBlockParser
                v-for="block in streamBlocksWithFootnotes.processedBlocks"
                :block="block"
                :key="block.id"
                pageContext="publication"
                class="block"
            />
            <FootnotesList
                v-if="streamBlocksWithFootnotes.footnotes && streamBlocksWithFootnotes.footnotes.length"
                :footnotes="streamBlocksWithFootnotes.footnotes"
            />
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    blocks: {
        type: Array,
        default: () => [],
    },
    heading: {
        type: String,
        default: '',
    },
});

const streamBlocksWithFootnotes = computed(() => getStreamBlocksWithFootnotes(props.blocks));

const anchorId = computed(() => {
    return `${titleToSlug(props.heading)}`;
});
</script>

<style lang="scss" scoped>
.publication-stream-blocks {
    max-width: 65rem;
    margin: 0 auto;
}

.heading {
    display: inline-flex;
    align-items: center;
    gap: 0.8rem;
    margin-bottom: 0.8rem;
}

h2 {
    padding-top: 0.5em;
    position: relative;
    &::before {
        content: '';
        position: absolute;
        height: 0.13em;
        width: 3.5em;
        background-color: rgb(var(--color-rgb-teal-default));
        inset-inline-start: 0;
        top: 0;
    }
}

.heading-icon {
    flex: 1 0 auto;
    width: 1.8rem;
    height: 1.8rem;
    margin-top: -0.4rem;
}

.blocks {
    display: flex;
    flex-direction: column;
    gap: 3.6rem;
}
</style>
